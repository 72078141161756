/// <reference path="../../services/generic.services.ts" />
/// <reference path="../../services/files.services.ts" />

class adminMainCtrl extends baseEntitiesCtrl {
    static $inject: Array<string> = ['$scope', '$location', '$compile', 'socketService','toastr'];
    showcompleted: boolean = false;
    deletecounter: number = 0;
    toastrObj :any;
    genericServices: GenericServiceClass;
    fileServices: FilesServiceClass;
    constructor(
        public $scope,
        public $location,
        public $compile,
        public socketService: socketServiceFactory,
        public toastr
    ) {
        super($scope, $location, $compile, socketService);
        this.toastrObj = toastr;
        this.collection = 'workflows';
        var user:any = this.socketService.user; 
        this.genericServices = new GenericServiceClass(this.service.socket,this.service.namespace)
        this.fileServices = new FilesServiceClass(this.service.socket,this.service.namespace)

        if(!user.roles.find(R=>R._id == adminsRoleId ) && !user.roles.find(R=>R._id == workflowUserRoleId   ) && !user.roles.find(R=>R._id == noderedUserRoleId  )){
            this.$location.path("/") 
            this.$scope.$apply();
        }
        this.order = new orderby({ field: "_modified", reverse: true })
        this.socketService.onSignedin(() => {
            this.basequery = '_type eq \'instance\'';
           if (this.showcompleted == false) {
                this.basequery += " and state ne 'completed'";
            }
            // this.loaddata();
            this.getPagingData();
        });
    }
    adjustcompleted() {
        this.basequery = '_type eq \'instance\'';
      if (this.showcompleted == true) {
            this.basequery += " and state ne 'completed'";
        }
        this.page = 1;
        this.entities = [];
    //    this.loaddata();
        this.getPagingData();
    }

    async delete(id) {
        try {
            this.loading = true;
            await this.genericServices.delete(id, 'workflows');
            this.loading = false;
            this.entities.forEach((entity, idx) => {
                if (entity._id == id) {
                    this.entities.splice(idx, 1);
                }
            })
            this.deletecounter++;
            this.loading = false;
            if (this.entities.length == 0) {
                this.page = 1;
                this.entities = [];
                this.deletecounter = 0;
                this.loaddata();
            } else {
                this.loading = false;
                if (!this.$scope.$$phase) { this.$scope.$apply(); }
            }
        } catch (err) {
            // handleError(this.$scope, err);
            this.toastrObj.error(GetErrorMessage(err));
        }
    };

}

class adminFilesCtrl extends baseEntities2Ctrl {
    static $inject: Array<string> = ['$scope', '$location', '$compile','$routeParams', 'socketService','toastr'];
    showcompleted: boolean = false;
    deletecounter: number = 0;
    genericServices: GenericServiceClass;
    fileServices: FilesServiceClass;
    fileid: string = '';
    filedata: string = '';
    toastrObj :any;
    constructor(
        public $scope,
        public $location,
        public $compile,
        public socketService: socketServiceFactory,
        public $routeParams,
        public toastr
    ) {
        super($scope, $location, $compile, socketService);
        this.toastrObj = toastr;
        this.collection = 'files';
        this.genericServices = new GenericServiceClass(this.service.socket,this.service.namespace)
        this.fileServices = new FilesServiceClass(this.service.socket,this.service.namespace)

        this.order = new orderby({ field: "metadata._modified", reverse: true })
        this.socketService.onSignedin(() => {
            this.basequery = {};
            this.loaddata();
        });
    }
    adjustcompleted() {
        this.basequery = {};
        this.page = 1;
        this.entities = [];
        this.loaddata();
    }

    async delete(id) {
        try {
            this.loading = true;
            await this.genericServices.delete(id, 'workflows');
            this.loading = false;
            this.entities.forEach((entity, idx) => {
                if (entity._id == id) {
                    this.entities.splice(idx, 1);
                }
            })
            this.deletecounter++;
            this.loading = false;
            if (this.entities.length == 0) {
                this.page = 1;
                this.entities = [];
                this.deletecounter = 0;
                this.loaddata();
            } else {
                this.loading = false;
                if (!this.$scope.$$phase) { this.$scope.$apply(); }
            }
        } catch (err) {
            // handleError(this.$scope, err);
            this.toastrObj.error(GetErrorMessage(err));
        }
    };


    async deletefile(id) {
        try {
            await this.fileServices.deletefile(id);
            this.page = 1;
            this.entities = [];
            this.deletecounter = 0;
            this.loaddata();
            //if (!me.$scope.$$phase) { me.$scope.$apply(); }
        } catch (err) {
            // handleError(this.$scope, err);
            this.toastrObj.error(GetErrorMessage(err));
        }
    }
    upload(file) {
        var me = this;
        if (!file) { return; }
        var aReader = new FileReader();
        aReader.readAsDataURL(file);
        aReader.onload = async (evt) => {
            try {
                var _content = aReader.result;
                var id = await this.fileServices.savefile(file.name, _content, null);
                me.fileid = id; 
                this.page = 1;
                this.entities = [];
                this.deletecounter = 0;
                this.loaddata();
            } catch (err) {
                // handleError(me.$scope, err);
                this.toastrObj.error(GetErrorMessage(err));
            }
        };
        aReader.onerror = (evt) => {
        };
    };
    async download(id) {
        var me = this;
        try {
            me.fileid = id;
            if (!me.$scope.$$phase) { me.$scope.$apply(); }
            var content = await this.fileServices.getfile(id);
 
            var blobimg = me.b64toBlob(content.body, content.contentType, 512);
            me.filedata = URL.createObjectURL(blobimg);
    
            if (!me.$scope.$$phase) { me.$scope.$apply(); }
    
            // var blobimg = me.b64toBlob(content.body, content.contentType, 512);
            // var blobimgUrl = URL.createObjectURL(blobimg);
            // var img = document.createElement('img');
            // img.src = blobimgUrl;
            // document.body.appendChild(img);
    
    
            // var blobtext = me.b64toBlob(content.body, content.contentType, 512);
            // var blobtextUrl = URL.createObjectURL(blobtext);
            // var a = document.createElement('a');
            // a.href = blobtextUrl;
            // a.target = "_blank";
            // a.innerText = "download";
            // document.body.appendChild(a);
    
        } catch (err) {
            // handleError(me.$scope, err);
            this.toastrObj.error(GetErrorMessage(err));
        }
    }


    b64toBlob(b64Data, contentType, sliceSize) {
        contentType = contentType || '';
        sliceSize = sliceSize || 512;

        var byteCharacters = atob(b64Data);
        var byteArrays:any = [];

        for (var offset = 0; offset < byteCharacters.length; offset += sliceSize) {
            var slice = byteCharacters.slice(offset, offset + sliceSize);

            var byteNumbers = new Array(slice.length);
            for (var i = 0; i < slice.length; i++) {
                byteNumbers[i] = slice.charCodeAt(i);
            }

            var byteArray = new Uint8Array(byteNumbers);

            byteArrays.push(byteArray);
        }

        var blob = new Blob(byteArrays, { type: contentType });
        return blob;
    }

}
